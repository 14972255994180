import React, { useEffect, useState } from 'react'

import { Table, Tag,Empty } from 'antd';

import REQUESTS from '../../../api/requests';

import getMyDate from '../../../components/getMyDate';

export default function ActivationsLogs({ mac }) {

    const [limit, setLimit] = useState(10);

    const [total, setTotal] = useState(10);

    const [currentPage, setCurrentPage] = useState(1);

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: "By",
            align: "center",
            dataIndex: "by",
        },
        {
            title: "Type",
            align: "center",
            dataIndex: "type",
            render: (text) => <Tag color={text === "activated" ? "green" : 'volcano'} key={text}>
                {text}
            </Tag>
        },
        {
            title: "Date",
            align: "center",
            dataIndex: "createdAt",
            render: (text) => getMyDate(text),
        },

    ];

    const getActivationLogs = () => {
        setLoading(true);

        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            filter: JSON.stringify({ mac }),
            sort:JSON.stringify(["id", "DESC"])
        }

        REQUESTS.DEVICE_ACTIVATIONS(query, (data) => {
            setLoading(false)
            setData(data.rows)
            setTotal(data.count)
        }, (err) => setLoading(false))
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    useEffect(() => {
        let timeout = setTimeout(() => {
            getActivationLogs()
        }, 500);

        return () => clearTimeout(timeout);

    }, [limit, currentPage,mac])
    return (
        <div>
            <p className="table-title">Activation Logs</p>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                loading={loading}
                scroll={{ x: "max-content" }}
                size="small"
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
            />
        </div>
    )
}
