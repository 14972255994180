import React, { useEffect, useState } from 'react'

import { Table ,Empty} from 'antd';

import REQUESTS from '../../api/requests';

import getMyDate from '../../components/getMyDate';

export default function RestoreLogs() {
  const [total, setTotal] = useState(10);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => {
        return (currentPage - 1) * total + index + 1;
      },
    },
    {
      title: "From device",
      fixed: "left",
      align: "center",
      render: (record) => {
        return <div>
          <div> {record.from_model}</div>
          <div>
            {record.from_device_mac}
          </div>
        </div>
      }
    },
    {
      title: "To device",
      fixed: "left",
      align: "center",
      render: (record) => {
        return <div>
          <div> {record.to_model}</div>
          <div>
            {record.to_device_mac}
          </div>
        </div>
      }
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      fixed: "left",
      align: "center",
      render: (record) => {
        return getMyDate(record)
      }
    },
  ];

  const getRestoreLogs = () => {
    setLoading(true);

    const query = {
      page: currentPage,
      limit,
      pagination: 1,
    };

    REQUESTS.DEVICE_LOGS(query, (data) => {
      setLoading(false)
      setData(data.rows)
      setTotal(data.count)
      setLimit(data.limit)
    }, () => setLoading(false))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getRestoreLogs();
    }, 500);

    return () => {
      clearTimeout(timout);
    };
  }, [currentPage, limit]);

  return (
    <div>
      <p className="table-title">Restore Logs</p>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        size="small"
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
      />
    </div>
  )
}
