import { useState, useEffect, useRef, } from "react";

import { useSelector } from "react-redux";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import country from "../../../country.json";
import ErrorMessage from "../../../components/ErrorMessage";
import { Button, Form, Input, Checkbox, Drawer, Select } from "antd";
import classes from "../style/reselerProfile.module.scss";
const { Option } = Select;

const ResRefDrawer = ({
  onClose,
  visible,
  current,
  getData,
  followAddElement,
  type = "",
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [referral, setReferral] = useState(current?.is_referral || false);
  const [reseller, setReseller] = useState(current?.is_reseller || false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [actionType, setActionType] = useState("Add");
  const [userType, setUserType] = useState("Referral");
  const [updatePlaylist, setUpdatePlaylist] = useState(false);

  const [resellers, setResellers] = useState([])
  const [search, setSearch] = useState("");

  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef(null);

  const translation = useSelector(selectTranslation);

  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  const getResellers = () => {

    const query = {
      search: {},
    };

    query.search['name'] = search;

    query.between = JSON.stringify({
      parent_id: {
        from: 1,
      }
    });

    function callback(data) {

      const newData = data.rows.map(item => ({
        value: item.id,
        label: item.name,
      }));

      setResellers(newData)
    }

    function errorCallback(err) {
      setLoading(false);
    }

    query.search = JSON.stringify(query.search);

    REQUESTS.GET_RESELLERS(query, callback, errorCallback);
  }

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      name: values.name,
      surname: values.surname || "",
      email: values.email,
      phone: inputValue,
      company: values.company || "",
      address: values.address || "",
      country: values.country || "",
      is_referral: referral,
      is_reseller: reseller,
    };

    if (userType === "Reseller") {
      body["update_playlist"] = updatePlaylist;
    } else {
      delete body["update_playlist"];
    }

    if (current && current.parent_id) {
      body['parent_id'] = values.parent_id
    } else {
      delete body['parent_id']
    }

    if (values.password.length) {
      body.password = values.password.trim();
    }


    function callback() {
      setLoading(false);

      if (!current) {
        followAddElement.setCurrentPage(1);
        followAddElement.setSearch("");
        followAddElement.setSort(["id", "DESC"]);
        followAddElement.setCurrentPage(1);
        followAddElement.setLimit(10);
        followAddElement.setTotal();
      }

      onClose();
      setInputValue("")
      getData();
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }
    
    if (!referral && !reseller) {

      setLoading(false);
      setMessage({ type: false, text: "Please select referral or reseller" });

    } else {
      if (current) {

        body.id = current.id;
        REQUESTS.REFERRAL_RESELLER.EDIT(body, callback, errorCallback);

      } else {

        REQUESTS.REFERRAL_RESELLER.ADD(body, (data) => {
          setLoading(false); onClose();
          getData();
        }, errorCallback);

      }
    }
  };

  useEffect(() => {
    if (visible) {
      if (current) {
        setReferral(current.is_referral);
        setReseller(current.is_reseller);

        setActionType("Edit");
        setUpdatePlaylist(current.update_playlist);
        setInputValue(current.phone)
        form.setFields([
          {
            name: "name",
            value: current.name,
          },

          {
            name: "surname",
            value: current.surname,
          },

          {
            name: "company",
            value: current.company,
          },

          {
            name: "country",
            value: current.country,
          },

          {
            name: "phone",
            value: current.phone,
          },

          {
            name: "email",
            value: current.email,
          },

          {
            name: "address",
            value: current.address,
          },

          {
            name: "password",
            value: "",
          },
        ]);

        if (current.parent_id) {
          REQUESTS.GET_RESELLERS({ search: JSON.stringify({ id: current.parent_id }) }, (data) => {
            if (data.rows[0]) {
              form.setFields([
                {
                  name: "parent_id",
                  value: data.rows[0].name,
                },])
            }
          }, (error) => { });
        }

      } else {
        form.resetFields();
        setActionType("Add");
        setUpdatePlaylist(true);
      }
    } else {
      setLoading(false);
      setUpdatePlaylist(false);
      onValuesChange();
      setReseller(true);
      setReferral(true);
      setInputValue("")
    }
  }, [visible, current]);

  useEffect(() => {
    if (type === "referral") {
      setUserType("Referral");
    } else {
      setUserType("Reseller");
    }
  }, [type]);

  useEffect(() => {
    const handleChangeWindowInnerWidth = () =>
      setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleChangeWindowInnerWidth);
    return () =>
      window.removeEventListener("resize", handleChangeWindowInnerWidth);
  }, []);

  useEffect(() => {
    let id = setTimeout(() => {
      getResellers()
    }, 500);

    return () => clearTimeout(id);

  }, [search])

  return (
    <Drawer
      title={`${translation[actionType] || TEXT[actionType]} 
      ${translation[userType] || TEXT[userType]}`}
      placement="right"
      onClose={onClose}
      open={visible}
      width={windowWidth > 623 ? 560 : "91vw"}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <div className={classes["ref-res-drawer"]}>
          <Form.Item
            label={translation["Name"] || TEXT["Name"]} name="name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="surname"
            label={translation["Surname"] || TEXT["Surname"]}
          >
            <Input />
          </Form.Item>
          <div className="phone-inp-container">
            <label className="phone-label">Phone</label>
            <input
              type="text"
              value={inputValue}
              name="phone"
              ref={inputRef}
              onFocus={() => {

              }}
              onChange={(e) => {
                const validInput = e.target.value.replace(/[^+0-9]/g, '');
                if (validInput.includes('+')) {
                  setInputValue(validInput);
                } else {
                  setInputValue(`+${validInput}`);
                }
              }
              }
              onKeyDown={(e) => {
                const cursorPosition = inputRef.current.selectionStart;
                if (cursorPosition == 0) {
                  inputRef.current.selectionStart = e.target.value.length;
                }
              }}
              className="phone-input"
            />
          </div>

          <Form.Item
            name="email"
            label={translation["Email"] || TEXT["Email"]}
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="company"
            label={translation["Company"] || TEXT["Company"]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label={translation["Address"] || TEXT["Address"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="country"
            label={translation["Country"] || TEXT["Country"]}
          >
            <Select showSearch={true} className={classes["select"]}>
              {country.map((item) => {
                return (
                  <Option key={item.code} value={`${item.name}`}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="password"
            label={translation["Password"] || TEXT["Password"]}
            rules={[
              {
                required:!current,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          {current?.parent_id &&
            <Form.Item
              name="parent_id"
              label={translation["Parent Reseller"] || TEXT["Parent Reseller"]}
            >
              <Select
                showSearch
                className={classes["select"]}
                onSearch={setSearch}
                options={resellers}
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  return (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase());
                }}
              />
            </Form.Item>
          }

          <div className={classes["form_checkbox_div"]}>
            <Checkbox
              className={classes["checkbox-form"]}
              checked={referral}
              onClick={() => {
                setReferral(!referral);
              }}
            >
              {translation["Referral"] || TEXT["Referral"]}
            </Checkbox>

            <Checkbox
              className={classes["checkbox-form_midele"]}
              onClick={() => {
                setReseller(!reseller);
              }}
              checked={reseller}
            >
              {translation["Reseller"] || TEXT["Reseller"]}
            </Checkbox>
            {
              (userType === "Reseller" && (
                <Checkbox
                  className={classes["checkbox-form_midele"]}
                  onClick={(e) => {
                    setUpdatePlaylist(e.target.checked);
                  }}
                  checked={updatePlaylist}
                >
                  {translation["Update playlist"] || TEXT["Update playlist"]}
                </Checkbox>
              ))
            }
          </div>
        </div>

        <ErrorMessage message={message} />

        <div className={classes["save_button_div"]}>
          <Form.Item>
            <Button
              className={classes["save_button"]}
              type="primary"
              loading={loading}
              onClick={() => form.submit()}
            >
              {translation["Save"] || TEXT["Save"]}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};

export default ResRefDrawer;
