const CONSTANTS = {
    JWT:"JWT",
    REMEMBER_ME: "REMEMBER_ME",
    
    APP_NAME: process.env.REACT_APP_APPLICATION_NAME || "XPLAYER ADMIN",

    API_HOST: process.env.REACT_APP_API_URL || "http://api.x-players.com",
    // API_HOST: "https://api.matplayer.com",

    APP_COLOR: "black",
    LINK_COLOR:"white",
};

export default CONSTANTS;
